.option {
  div {
    display: flex;
  }
  .ticker {
    min-width: 4.5rem;
  }
  .title {
    opacity: 0.6;
  }
}
